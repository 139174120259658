import { faAngleLeft, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import LayoutLanding from "../components/LayoutLanding";
import Example from "../components/ModalSurvey";
const eye = <FontAwesomeIcon icon={faEye} />;

export default function WaitingListPage() {
  function copyCodeToClipboard() {
    try {
      const input = document.getElementById("inputCode");
      const el = input;
      el.select();
      document.execCommand("copy");
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    var requestOptionsz = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/waitingList/BusinessElements`, requestOptionsz)
      .then((response) => response.text())
      .then((result) => {
        let html = "";
        var json = JSON.parse(result);
        console.log(json);
        json.forEach((js) => {
          let image = js.LogoPath;
          if (image == null) {
            image = "../img/notfound.png";
          } else {
            image = `${window.$apiurl}/` + js.LogoPath + "";
          }
          let htmlSegment = `<div class="user">
                          </div>
        <div class="card-deal mb-5 card"><div class="card-body"><div class="row"><div class="col-xl-2 col-lg-2 col-md-2 col-3 mainimagestyle"><img src="${image}" alt="deal pic" class="pe-4"></div><div class="col-xl-6 col-lg-6 col-md-6 col-6 pt-2 ps-4"><p class="mb-0"><a href="/business-detail?id=${js.Id}">${js.Name}</a></p><p class="text-muted fw-airlight fs-12 mb-0 opacity-8">${js.Category} | $${js.PriceRange}</p><p class="primary-color fs-12"><span><img src="../img/Location.svg" class="pe-1 pb-1" alt="star"></span>0.3 km</p></div><div class="col-xl-4 col-lg-4 col-md-4 col-3 text-end pt-2"><p class="primary-color fs-10 mb-4"><span><img src="../img/Star-deal.svg" class="pe-1 pb-1" alt="star"></span>${js.StarRating}</p><div class="pt-2"><img src="../img/Logo-deal.svg" class="pe-1" alt="logo"><img src="../img/Logo-deal2.svg" alt="logo"></div></div></div></div></div>                      
                          
                          
                          
                          `;

          html += htmlSegment;
        });

        let container = document.querySelector(".demo");
        container.innerHTML = html;
      })
      .catch((error) => console.log("error", error));

    var access = localStorage.getItem("waitingtoken");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + access + "");
    myHeaders.append(
      "Cookie",
      ".AspNet.Cookies=mrkE_tMJJ42xPveqHF65G7sH3fkZmxugg0TAdDLPXthp5DDIJWGQPnECwuh9pfLEvs1ZX3YJQogfuOh97llaCIR-h23o_VJBwrbnVLPA54SXZkmEbJQ7UYvN3CULWiIiV-mvc0jihL9vgdN9kx-iiYO4npSibPNYhW12__iaoUhtFB1LwjtXVSVOeIKObE19PnyVixiHhCJ8s2nkarmuCLpEVUOY5IEFX0RCut4STQMzFlNLMK5eIy-NmWZrzCD1jMYCkGrProBZRDYC83z994dkbcCiemhBajCXDkpL62oiQgTMiGx3eoe5wTiInYtcqJ1gWEDx4S_TxmAvMldadtyYq72YjQlMwlQ4Qtc8lr7EwaI3S8iTuf0me9ffyL679YMjfZFRoQ13jxAWJB0eYtOiNF3QPF4H6JhnTqIKiqw6bA12uSDxFu1pqn1ZsZf5MnSO8QJ9BDIvPurqTEk60vEigtCcBqgE__WL_0RfNZo"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/waitingList/invitationCode`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var json = JSON.parse(result);
        document.getElementById("codeinput").value = json;
        document.getElementById("inputCoded").value = json;
      })
      .catch((error) => console.log("error", error));

    var myHeaderss = new Headers();
    myHeaderss.append("Authorization", "Bearer " + access + "");
    myHeaderss.append(
      "Cookie",
      ".AspNet.Cookies=mrkE_tMJJ42xPveqHF65G7sH3fkZmxugg0TAdDLPXthp5DDIJWGQPnECwuh9pfLEvs1ZX3YJQogfuOh97llaCIR-h23o_VJBwrbnVLPA54SXZkmEbJQ7UYvN3CULWiIiV-mvc0jihL9vgdN9kx-iiYO4npSibPNYhW12__iaoUhtFB1LwjtXVSVOeIKObE19PnyVixiHhCJ8s2nkarmuCLpEVUOY5IEFX0RCut4STQMzFlNLMK5eIy-NmWZrzCD1jMYCkGrProBZRDYC83z994dkbcCiemhBajCXDkpL62oiQgTMiGx3eoe5wTiInYtcqJ1gWEDx4S_TxmAvMldadtyYq72YjQlMwlQ4Qtc8lr7EwaI3S8iTuf0me9ffyL679YMjfZFRoQ13jxAWJB0eYtOiNF3QPF4H6JhnTqIKiqw6bA12uSDxFu1pqn1ZsZf5MnSO8QJ9BDIvPurqTEk60vEigtCcBqgE__WL_0RfNZo"
    );

    var requestOptionss = {
      method: "GET",
      headers: myHeaderss,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/waitingList/status`, requestOptionss)
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        var json = JSON.parse(result);
        console.log(json.SurveyFilled);
        document.querySelector(".invitevalue").innerText = json.InvitesUsed;
        document.getElementById("invitenumber").innerText = json.InvitesUsed;
        if (document.getElementById("invitenumber").innerText == 3) {
          var element = document.getElementById("invitenumberstatus");
          element.classList.add("primary-color");
          element.classList.remove("text-muted");
        } else {
          var element = document.getElementById("invitenumberstatus");
          element.classList.add("text-muted");
        }

        document.getElementById("fillednumber").innerText = json.SurveyFilled;
        if (document.getElementById("fillednumber").innerText == 1) {
          var element = document.getElementById("fillednumberstatus");
          element.classList.add("primary-color");
          element.classList.remove("text-muted");
        } else {
          var element = document.getElementById("fillednumberstatus");
          element.classList.add("text-muted");
        }

        document.getElementById("tagnumbers").innerText = json.SocialTags;

        if (document.getElementById("tagnumbers").innerText == 1) {
          var element = document.getElementById("tagnumbersstatus");
          element.classList.add("primary-color");
          element.classList.remove("text-muted");
        } else {
          var element = document.getElementById("tagnumbersstatus");
          element.classList.add("text-muted");
        }
      })
      .catch((error) => console.log("error", error));

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Email: "test@gmail.com",
      Password: "1234567Azerty!",
      ConfirmPassword: "123456Azerty!",
      PhoneNumber: "+2126077251239",
      FullName: "ysf",
      InvitedCode: "Bogo-50137",
    });

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/api/waitingList/BusinessElements`, requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  });

  const [showSuccess, setShowSuccess] = React.useState("-1");
  const [showResults, setShowResults] = React.useState(0);
  const [showPremium, setShowPremium] = React.useState("0");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShowSuccess("0");
    setTimeout(() => {
      setShow(true);
    }, 20);
  };
  function Copy() {
    /* Get the text field */
    var copyText1 = document.getElementById("codeinput");

    /* Select the text field */
    copyText1.select();
    copyText1.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText1.value);

    /* Alert the copied text */
    copyText1.style.borderColor = "#3CBC8D";
  }
  function CopySecond() {
    /* Get the text field */
    var copyText = document.getElementById("inputCoded");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);

    /* Alert the copied text */
    copyText.style.borderColor = "#3CBC8D";
  }
  return (
    <LayoutLanding>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="margin-inline-start border-0">
          <Modal.Title className="pe-xl-4 pe-lg-4 pe-md-0 pe-0 fw-airbold">
            Redeem this voucher
          </Modal.Title>
          <button
            type="button"
            className="primary-color bg-transparent border-0 ps-5"
            aria-label="Close"
            onClick={handleClose}
          >
            Close
          </button>
        </Modal.Header>
        <Modal.Body classNmae="pb-5">
          {showSuccess === "0" ? (
            <div className="Card-Ticket">
              <div className="col-xl-8 col-lg-8 col-md-10 col-11 mx-auto ticket-review">
                <Card className="">
                  <Card.Body className="review">
                    <Row>
                      <Col xl="8" lg="8" md="6" xs="6">
                        <Row>
                          <Col xl="4" lg="4" md="5" xs="6">
                            <img
                              src="../img/Logo-ticket.svg"
                              alt="logo"
                              className="me-2"
                            />
                          </Col>
                          <Col xl="8" lg="8" md="7" xs="6">
                            <p className="text-muted fs-8 mb-0"> BUY</p>
                            <p className="fs-10 mb-1">Sushi Platter</p>
                            <p className="text-muted fs-8 mb-0"> GET</p>
                            <p className="fs-10 mb-0">Sushi Platter</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl="4" lg="4" md="6" xs="6" className="text-end">
                        <p className="text-muted fs-8 mb-0">VALIDITY</p>
                        <span className="d-flex justify-content-end">
                          <span className={" pt-0 ps-0 pe-1  fs-8 mb-0"}>
                            S
                          </span>
                          <span className={" pt-0 ps-0 pe-1  fs-8 mb-0"}>
                            {" "}
                            M
                          </span>
                          <span className={" pt-0 ps-0 pe-1  fs-8 mb-0"}>
                            {" "}
                            T
                          </span>
                          <span className={" pt-0 ps-0 pe-1  fs-8 mb-0"}>
                            {" "}
                            W{" "}
                          </span>
                          <span className={" pt-0 ps-0 pe-1  fs-8 mb-0"}>
                            {" "}
                            T
                          </span>
                          <span className={" pt-0 ps-0 pe-1  fs-8 mb-0"}>
                            {" "}
                            F{" "}
                          </span>
                          <span className={" pt-0 ps-0 pe-1  fs-8 mb-0"}>
                            {" "}
                            S
                          </span>
                        </span>
                        <p className="text-muted fs-8 mb-0"> EST. SAVINGS</p>
                        <p className="primary-color fs-10 mb-0">
                          AED <span>215</span>
                        </p>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className="bg-white border-bottom pt-3">
                    <Col xl="12">
                      <Row>
                        <Col xl="5" lg="5" md="6" xs="6">
                          <p className="fs-12 fw-airbold mb-1">VOUCHER CODE</p>
                          <p className="primary-color fs-14 mb-1">BOGO128173</p>
                        </Col>
                        <Col xl="7" lg="7" md="6" xs="6">
                          <p className="fs-12 fw-airbold mb-1">MERCHANT PIN</p>
                          <p className="fs-12 fw-airbold mb-1 text-muted">
                            N/A
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Card.Footer>
                  <Card.Footer className="bg-white">
                    <Col xl="12">
                      <Row>
                        <Col xl="6" lg="6" md="6" xs="6">
                          <p className="fs-12 fw-airbold mb-2">VALIDITY</p>
                          <p className={"fs-14 mb-0 primary-color"}>
                            S{" "}
                            <span className="text-dark fw-airlight ps-2">
                              11:00 - 23:00
                            </span>
                          </p>
                          <p className={"fs-14 mb-0 primary-color"}>
                            M{" "}
                            <span className="text-dark fw-airlight ps-2">
                              11:00 - 23:00
                            </span>
                          </p>
                          <p className={"fs-14 mb-0 primary-color"}>
                            T{" "}
                            <span className="text-dark fw-airlight ps-2">
                              11:00 - 23:00
                            </span>
                          </p>
                          <p className={" fs-14 mb-0 primary-color"}>
                            W{" "}
                            <span className="text-dark fw-airlight ps-2">
                              11:00 - 23:00
                            </span>
                          </p>
                          <p className={" fs-14 mb-0 primary-color"}>
                            T{" "}
                            <span className="text-dark fw-airlight ps-2">
                              11:00 - 23:00
                            </span>
                          </p>
                          <p className={" fs-14 mb-0"}>
                            F{" "}
                            <span className="text-dark fw-airlight ps-2">
                              Not available
                            </span>
                          </p>
                          <p className={" fs-14 mb-0"}>
                            S{" "}
                            <span className="text-dark fw-airlight ps-2">
                              Not available
                            </span>
                          </p>
                        </Col>
                        <Col xl="6" lg="6" md="6" xs="6">
                          <p className="fs-12 fw-airbold mb-2">FINE PRINT</p>
                          <p className="fw-airlight fs-10 lh-30">
                            This Buy 1 Get 1 Voucher excludes all Family-sized
                            Platters.
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col xl="12">
                      <Row>
                        <div className="my-toggle-btn-wrapper mt-4">
                          <div className="my-toggle-btn">
                            <input
                              type="checkbox"
                              id="checkbox1"
                              onChange={(e) => {
                                setShowSuccess(e.target.checked);
                              }}
                            />
                            <label for="checkbox1">
                              <span className="on fs-13 fw-airbold">
                                Slide to redeem
                              </span>
                              <span className="off fs-13 fw-airbold">
                                Slide to redeem
                              </span>
                            </label>
                          </div>
                        </div>
                      </Row>
                    </Col>
                  </Card.Footer>
                </Card>
              </div>
            </div>
          ) : (
            <div className="my-5 text-center">
              <div className="col-xl-8 col-lg-8 col-md-10 col-11 mx-auto py-5">
                <img src="../img/success.svg" alt="success icon" width="150" />
                <h1 className="fs-22 fw-airbold my-4">Success!</h1>
                <p className="fw-airbold text-muted">
                  You have redeemed your voucher!
                </p>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      {showResults === 0 ? (
        <>
          <main>
            <Container>
              <section>
                <div className="col-xl-7 col-lg-7 col-md-12 text-center mx-auto pt-4">
                  <h1 className=" fw-800 mb-5 fs-44">
                    Welcome to BOGO’s Waiting List!
                  </h1>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-12 text-center mx-auto">
                  <h5 className="fw-airlight lh-28 mt-3 mb-1">
                    <span className="fw-airbold fw-800">
                      Our Invite-Only Program
                    </span>
                  </h5>
                  <h5 className="fw-airlight lh-28 fs-18 mt-3 mb-5 px-xl-5 px-lg-5 px-md-4 px-0 ">
                    offers thousands in savings opportunities and tons of
                    rewards, perks and freebies!
                  </h5>
                </div>
              </section>
              <section>
                <Col
                  xl="5"
                  lg="5"
                  md="12"
                  className="mx-auto px-xl-4 px-lg-4 px-md-4 px-0 mb-5"
                >
                  <Form>
                    <Form.Group
                      className="mb-3 pass-wrapper"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        Your Invite Code
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Your Invite Code"
                        className="fw-airlight InviteCode"
                        id="codeinput"
                        disabled
                      />
                      <i
                        role="button"
                        aria-hidden="true"
                        className="text-muted fs-10 py-3 font-normal"
                        onClick={() => {
                          Copy();
                        }}
                      >
                        Click to copy
                      </i>
                    </Form.Group>
                    <p className="fs-13 text-center fw-airbold">
                      <span className="invitevalue"></span>/3 invites used
                    </p>
                    <Button
                      className="w-100 fs-14 py-3"
                      onClick={() => {
                        setShowResults(1);
                      }}
                      type="submit"
                    >
                      Your BOGO Premium progress
                    </Button>
                  </Form>
                </Col>
                <Col xl="5" lg="5" md="12" className="mx-auto">
                  <h4 className="fw-airbold fs-22 mb-5">
                    Merchants running BOGO deals now:
                  </h4>
                  <div className="demo"></div>
                </Col>
              </section>
              <section className="show-more py-5">
                <p className="fs-14 text-center fw-airbold mb-2">
                  More deals coming soon...
                </p>
                <p className="fs-13 text-center text-muted">
                  We’re adding new companies every day!
                </p>
              </section>
            </Container>
          </main>
        </>
      ) : showResults === 1 ? (
        <>
          <main>
            <Container>
              <section>
                <div className="row">
                  <div
                    className="col-xl-1 col-lg-1 col-md-1 col-2 text-start"
                    onClick={() => {
                      setShowResults(0);
                    }}
                  >
                    <Link className="btn btn-link">
                      <img src="../img/Arrowleft.svg" alt="previous" />
                    </Link>
                  </div>
                  <div className="col-xl-10 col-lg-10 col-md-10 col-10 text-center">
                    <h1 className="fs-44 fw-bold mb-3">
                      Earn free BOGO Premium
                    </h1>
                    <h4 className="fs-22 fw-bold mb-5">
                      Complete these activities to earn your free BOGO Premium
                      plan
                    </h4>
                  </div>
                </div>
              </section>
              <section>
                <Col
                  xl="5"
                  lg="5"
                  md="12"
                  className="mx-auto px-xl-4 px-lg-4 px-md-4 px-0 mb-5"
                >
                  <Form>
                    <Form.Group
                      className="mb-3 pass-wrapper"
                      controlId="formBasicEmail"
                    >
                      <Form.Label className="bogo-par fs-13 text-start fw-airlight">
                        Your Invite Code
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Your Invite Code"
                        className="fw-airlight InviteCode"
                        id="inputCoded"
                      />
                      <i
                        role="button"
                        aria-hidden="true"
                        className="text-muted fs-10 py-3 font-normal"
                        onClick={() => {
                          CopySecond();
                        }}
                      >
                        Click to copy
                      </i>
                    </Form.Group>
                    <p className="fs-13 text-center fw-airbold">
                      <span className="invitevalue"></span>/3 invites used
                    </p>
                  </Form>
                </Col>
              </section>

              <section>
                <div className="col-xl-10 mx-auto">
                  <Row>
                    <Col xl="6" lg="6" md="6">
                      <h5 className="fw-airbold fs-20">
                        Your free BOGO Premium subscription progress
                      </h5>
                      <h6 className="fw-airlight pe-xl-5 pe-lg-5 pe-md-5 me-xl-5 pe-0 me-lg-5 me-md-5 me-0 fw-400">
                        You’re able to earn up to 12 months of complimentary
                        BOGO Premium subscription by completing the following
                        activities during our Pre-Launch Promotional Period.
                      </h6>
                      <p className="fs-12 text-muted fw-airlight mb-0">
                        Waiting List Promotional Period ends:
                      </p>
                      <p className="fs-12 text-muted fw-airlight mb-1">
                        15/02/2021
                      </p>
                      <p
                        className="primary-color fs-16 fw-500 text-decoration-none"
                        type="button"
                        onClick={() => {
                          setShowPremium("1");
                        }}
                      >
                        Activities that earn you BOGO Premium
                      </p>
                      {showPremium !== "0" ? (
                        <>
                          <div className="mb-4">
                            <p className="fw-airlight mb-1">Your Invites:</p>
                            <ul class="pe-5">
                              <li class="fw-airlight">
                                1 month for all 3 of your invites being used
                              </li>
                              <li class="fw-airlight">
                                {" "}
                                1 month for each of your invitees who redeem at
                                least one Pre-Launch BOGO Voucher (up to 3
                                months)
                              </li>
                            </ul>
                          </div>
                          <div className="mb-4">
                            <p className="fw-airlight mb-1">
                              {" "}
                              Using The BOGO App:
                            </p>
                            <ul class="pe-5">
                              <li class="fw-airlight">
                                1 Pre-Launch BOGO Voucher = 1 month
                              </li>
                              <li class="fw-airlight">
                                {" "}
                                3 Pre-Launch BOGO Vouchers = 3 months
                              </li>
                              <li class="fw-airlight">
                                {" "}
                                5+ Pre-Launch BOGO Vouchers = 6 months
                              </li>
                            </ul>
                          </div>
                          <div className="mb-4">
                            <p className="fw-airlight mb-1">
                              {" "}
                              Helping BOGO Improve:
                            </p>
                            <ul class="pe-5">
                              <li class="fw-airlight">
                                1 month for completing one of our surveys
                              </li>
                            </ul>
                          </div>
                          <div className="mb-4">
                            <p className="fw-airlight mb-1"> Promoting BOGO:</p>
                            <ul class="pe-5">
                              <li class="fw-airlight">
                                1 month for tagging The BOGO App + our Merchant
                                Partner on social media, when you redeem a
                                Pre-Launch BOGO Voucher
                              </li>
                            </ul>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col xl="6" lg="6" md="6">
                      <Row>
                        <Col xl="6" lg="6" md="6" className="mb-4">
                          <Card className="border-0 bg-light text-center">
                            <Card.Body>
                              <img
                                src="../img/invite 1.svg"
                                alt="logo"
                                className="mb-2  mt-3"
                                width="40"
                                height="40"
                              />
                              <p className="fs-18 text-center fw-airbold mb-2">
                                <span id="invitenumber"></span>/3 invites used
                              </p>
                              <p className=" mb-2" id="invitenumberstatus">
                                Claimed
                              </p>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col xl="6" lg="6" md="6" className="mb-4">
                          <Card className="border-0 bg-light text-center">
                            <Example />
                          </Card>
                        </Col>
                        <Col xl="6" lg="6" md="6" className="mb-4">
                          <Card className="border-0 bg-light text-center">
                            <Card.Body>
                              <img
                                src="../img/social-tags.svg"
                                alt="logo"
                                className="mb-2  mt-3"
                                width="40"
                                height="40"
                              />
                              <p className="fs-18 text-center fw-airbold mb-2">
                                <span id="tagnumbers"></span>/1 social tags
                              </p>
                              <p className=" mb-2" id="tagnumbersstatus">
                                Claimed
                              </p>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col xl="6" lg="6" md="6" className="mb-4">
                          <Card className="border-0 bg-light text-center">
                            <Card.Body>
                              <img
                                src="../img/redeemed.svg"
                                alt="logo"
                                className="mb-2  mt-3"
                                width="40"
                                height="40"
                              />
                              <p className="fs-18 text-center fw-airbold mb-2">
                                0/3 invitees redeemed
                              </p>
                              <p className=" mb-2 text-muted">Claimed</p>
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col xl="12" lg="12" md="12" className="mb-4">
                          <Card className="border-0 bg-light text-center">
                            <Card.Body>
                              <img
                                src="../img/voucher.svg"
                                alt="logo"
                                className="mb-2  mt-3"
                                width="40"
                                height="40"
                              />
                              <p className="fs-18 text-center fw-airbold mb-2">
                                0 voucher(s) redeemed
                              </p>
                              <p className="text-muted mb-2">Claimed</p>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </section>
            </Container>
          </main>
        </>
      ) : (
        <main>
          <Container>
            <Col xl="5" lg="5" md="8" className="mx-auto">
              <section>
                <div
                  className="col-xl-12 col-lg-12 col-md-12 col-12 text-start mb-4"
                  onClick={() => {
                    setShowResults(0);
                  }}
                >
                  <Link className="btn btn-link text-decoration-none">
                    <img
                      src="../img/Arrowleft.svg"
                      alt="previous"
                      className="pe-3"
                    />
                    <span className="fs-18 text-muted fw-airlight opacity-8">
                      Back to Merchant Directory
                    </span>
                  </Link>
                </div>
                <div className="col-xl-12 text-center">
                  <h2 className="fw-airbold mb-4">HM Steakhouse</h2>
                  <img
                    src="../img/Steakhouse.png"
                    alt="Steakhouse"
                    className="rounded w-100"
                  />
                  <Link className="btn btn-link text-decoration-none Dots-Link">
                    <img src="../img/Dots.svg" alt="Dots" className="Dots" />
                  </Link>
                </div>
                <Col xl="12" className="pb-3">
                  <Row className="mx-auto">
                    <Col xl="8" lg="8" md="7" xs="7" className="px-0">
                      <p className="fs-18 text-muted fw-airlight opacity-8">
                        Steak, Seafood, Bar, International
                      </p>
                    </Col>
                    <Col xl="4" lg="4" md="5" xs="5" className="text-end px-0">
                      <p className="fs-18 text-muted fw-airlight opacity-8">
                        Price: $$$$
                      </p>
                    </Col>
                  </Row>
                </Col>
              </section>
              <section>
                <Col xl="12">
                  <h5 className="fs-22 mb-4">Dubai Marina</h5>
                  <div className="Card-third d-flex flex-wrap">
                    <a className="nav-link card-share first-child mb-4">
                      <span className=" py-4 px-3">
                        <img src="../img/food-menu.svg" alt="food-menu" />
                      </span>
                    </a>

                    <a className="nav-link card-share mb-0 ps-xl-3 ps-lg-0 ps-md-0 ps-0">
                      <span className=" py-4 px-3">
                        <img src="../img/call.svg" alt="phone" />
                      </span>
                    </a>

                    <a className="nav-link card-share mb-0 ps-xl-3 ps-lg-0 ps-md-0 ps-0">
                      <span className="py-4 px-3">
                        <img src="../img/email.svg" alt="email" />
                      </span>
                    </a>

                    <a className="nav-link card-share mb-0 ps-xl-3 ps-lg-0 ps-md-0 ps-0">
                      <span className=" py-4 px-3">
                        <img src="../img/share.svg" alt="share" />
                      </span>
                    </a>

                    <a className="nav-link card-share mb-0 ps-xl-3 ps-lg-0 ps-md-0 ps-0">
                      <span className=" py-4 px-3">
                        <img src="../img/star-svg.svg" alt="star" />
                      </span>
                    </a>
                  </div>
                </Col>
              </section>
              <section>
                <Col xl="12" className="pt-5">
                  <Row className="mx-auto">
                    <Col xl="8" lg="8" md="7" xs="7" className="px-0">
                      <p className="fs-22">Available vouchers</p>
                    </Col>
                    <Col xl="4" lg="4" md="5" xs="5" className="text-end px-0">
                      <p className="fs-22 fw-airlight">
                        3 months available deals
                      </p>
                    </Col>
                  </Row>
                </Col>
              </section>

              <secion className="Card-Ticket">
                <div className="col-xl-12 col-lg-12 col-md-12 mx-auto ticket mb-4">
                  <Card className="">
                    <Card.Body>
                      <Row>
                        <Col xl="8" lg="8" md="6" xs="6">
                          <Row>
                            <Col xl="3" lg="4" md="5" xs="6">
                              <img
                                src="../img/LogoCreate.svg"
                                alt="logo"
                                className="me-2"
                              />
                            </Col>
                            <Col xl="8" lg="8" md="7" xs="6">
                              <p className="text-muted fs-8 mb-0"> BUY</p>
                              <p className="fs-10 mb-1" id="buyitem1">
                                Steak
                              </p>
                              <p className="text-muted fs-8 mb-0"> GET</p>
                              <p className="fs-10 mb-0" id="buyitem2">
                                Steak
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col xl="4" lg="4" md="6" xs="6" className="text-end">
                          <p className="text-muted fs-8 mb-0">VALIDITY</p>
                          <span className="d-flex justify-content-end">
                            <span
                              className=" pt-0 ps-0 pe-1  fs-8 mb-0"
                              id="Sun"
                            >
                              S
                            </span>
                            <span
                              className=" pt-0 ps-0 pe-1  fs-8 mb-0"
                              id="Mon"
                            >
                              {" "}
                              M
                            </span>
                            <span
                              className=" pt-0 ps-0 pe-1  fs-8 mb-0"
                              id="Th"
                            >
                              {" "}
                              T
                            </span>
                            <span
                              className=" pt-0 ps-0 pe-1  fs-8 mb-0"
                              id="Wed"
                            >
                              {" "}
                              W{" "}
                            </span>
                            <span
                              className=" pt-0 ps-0 pe-1  fs-8 mb-0"
                              id="Thu"
                            >
                              {" "}
                              T
                            </span>
                            <span
                              className=" pt-0 ps-0 pe-1  fs-8 mb-0"
                              id="Fra"
                            >
                              {" "}
                              F{" "}
                            </span>
                            <span
                              className=" pt-0 ps-0 pe-1 fs-8 mb-0"
                              id="Sat"
                            >
                              {" "}
                              S
                            </span>
                          </span>
                          <p className="text-muted fs-8 mb-0"> EST. SAVINGS</p>
                          <p className="primary-color fs-10 mb-0">
                            AED <span id="itemestimated"></span>
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                    <Card.Footer className="bg-white">
                      <button
                        className="btn btn-primary text-white text-center w-100"
                        onClick={handleShow}
                      >
                        Voucher Details
                      </button>
                    </Card.Footer>
                  </Card>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 mx-auto ticket mb-4">
                  <Card className="">
                    <Card.Body>
                      <Row>
                        <Col xl="8" lg="8" md="6" xs="7">
                          <Row>
                            <Col xl="3" lg="4" md="5" xs="6">
                              <img
                                src="../img/LogoCreate.svg"
                                alt="logo"
                                className="me-2"
                              />
                            </Col>
                            <Col xl="8" lg="8" md="7" xs="6">
                              <p className="text-muted fs-8 mb-0"> BUY</p>
                              <p className="fs-10 mb-1" id="buyitem1">
                                Sushi Platter
                              </p>
                              <p className="text-muted fs-8 mb-0"> GET</p>
                              <p className="fs-10 mb-0" id="buyitem2">
                                Sushi Platter
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col xl="4" lg="4" md="6" xs="5" className="text-end">
                          <p className="text-muted fs-8 mb-0">VALIDITY</p>
                          <span className="d-flex justify-content-end">
                            <span
                              className=" pt-0 ps-0 pe-1  fs-8 mb-0"
                              id="Sun"
                            >
                              S
                            </span>
                            <span
                              className=" pt-0 ps-0 pe-1  fs-8 mb-0"
                              id="Mon"
                            >
                              {" "}
                              M
                            </span>
                            <span
                              className=" pt-0 ps-0 pe-1  fs-8 mb-0"
                              id="Th"
                            >
                              {" "}
                              T
                            </span>
                            <span
                              className=" pt-0 ps-0 pe-1  fs-8 mb-0"
                              id="Wed"
                            >
                              {" "}
                              W{" "}
                            </span>
                            <span
                              className=" pt-0 ps-0 pe-1  fs-8 mb-0"
                              id="Thu"
                            >
                              {" "}
                              T
                            </span>
                            <span
                              className=" pt-0 ps-0 pe-1  fs-8 mb-0"
                              id="Fra"
                            >
                              {" "}
                              F{" "}
                            </span>
                            <span
                              className=" pt-0 ps-0 pe-1 fs-8 mb-0"
                              id="Sat"
                            >
                              {" "}
                              S
                            </span>
                          </span>
                          <p className="text-muted fs-8 mb-0"> EST. SAVINGS</p>
                          <p className="primary-color fs-10 mb-0">
                            AED <span id="itemestimated"></span>
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                    <Card.Footer className="bg-white">
                      <button
                        className="btn btn-primary text-white text-center w-100"
                        onClick={handleShow}
                      >
                        Voucher Details
                      </button>
                    </Card.Footer>
                  </Card>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 mx-auto ticket mb-4">
                  <Card className="">
                    <Card.Body>
                      <Row>
                        <Col xl="8" lg="8" md="6" xs="6">
                          <Row>
                            <Col xl="3" lg="4" md="5" xs="6">
                              <img
                                src="../img/LogoCreate.svg"
                                alt="logo"
                                className="me-2"
                              />
                            </Col>
                            <Col xl="8" lg="8" md="7" xs="6">
                              <p className="text-muted fs-8 mb-0"> BUY</p>
                              <p className="fs-10 mb-1" id="buyitem1">
                                Sushi Platter
                              </p>
                              <p className="text-muted fs-8 mb-0"> GET</p>
                              <p className="fs-10 mb-0" id="buyitem2">
                                Sushi Platter
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col xl="4" lg="4" md="6" xs="6" className="text-end">
                          <p className="text-muted fs-8 mb-0">VALIDITY</p>
                          <span className="d-flex justify-content-end">
                            <span
                              className=" pt-0 ps-0 pe-1  fs-8 mb-0"
                              id="Sun"
                            >
                              S
                            </span>
                            <span
                              className=" pt-0 ps-0 pe-1  fs-8 mb-0"
                              id="Mon"
                            >
                              {" "}
                              M
                            </span>
                            <span
                              className=" pt-0 ps-0 pe-1  fs-8 mb-0"
                              id="Th"
                            >
                              {" "}
                              T
                            </span>
                            <span
                              className=" pt-0 ps-0 pe-1  fs-8 mb-0"
                              id="Wed"
                            >
                              {" "}
                              W{" "}
                            </span>
                            <span
                              className=" pt-0 ps-0 pe-1  fs-8 mb-0"
                              id="Thu"
                            >
                              {" "}
                              T
                            </span>
                            <span
                              className=" pt-0 ps-0 pe-1  fs-8 mb-0"
                              id="Fra"
                            >
                              {" "}
                              F{" "}
                            </span>
                            <span
                              className=" pt-0 ps-0 pe-1 fs-8 mb-0"
                              id="Sat"
                            >
                              {" "}
                              S
                            </span>
                          </span>
                          <p className="text-muted fs-8 mb-0"> EST. SAVINGS</p>
                          <p className="primary-color fs-10 mb-0">
                            AED <span id="itemestimated"></span>
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                    <Card.Footer className="bg-white">
                      <button
                        className="btn btn-primary text-white text-center w-100"
                        type="button"
                        onClick={handleShow}
                      >
                        Voucher Details
                      </button>
                    </Card.Footer>
                  </Card>
                </div>
              </secion>
              <section>
                <Col xl="12" className="pt-5">
                  <p className="fs-22">Location</p>
                </Col>
              </section>
            </Col>
          </Container>
        </main>
      )}
    </LayoutLanding>
  );
}
